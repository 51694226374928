import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import saveAccessTokenToCookie from '../helpers/saveAccessTokenToCookie'

import { Frame, Loading } from '@shopify/polaris'

export interface LoginProps extends RouteComponentProps {}

function Login(props: LoginProps) {
  saveAccessTokenToCookie(props.location)
  return (
    <div style={{ height: '100px' }}>
      <Frame>
        <Loading />
      </Frame>
    </div>
  )
}

export default Login
