import * as React from "react";
import { Card, TextContainer, Stack, ButtonGroup, Button } from "@shopify/polaris";
export interface SupportWidgetProps {}

const SupportWidget: React.SFC<SupportWidgetProps> = () => {
  return (
    <Card title="Contact Support" subdued>
      <Card.Section>
        <Stack spacing="loose" vertical>
          <p>
            Can't find what you're looking for? We're here to help! You can get in touch with us easily via live chat or
            emailing us on our support mail.
          </p>

          <Stack>
            <ButtonGroup>
              <Button
                onClick={() => {
                  // @ts-ignore
                  window.Intercom("show");
                }}
              >
                Live Chat
              </Button>
              <Button external url="mailto:support@codeword.tech" plain>
                Email Us
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>
      </Card.Section>
    </Card>
  );
};

export default SupportWidget;
