import * as React from "react";
import Wrapper from "../../cw-tools/frontend/Components/Wrapper";
import CustomizerContainer from "../../components/customize/CustomizerContainer";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import {
  Page,
  Layout,
  Card,
  SkeletonBodyText,
  Spinner,
} from "@shopify/polaris";

export interface CustomizeProps {}

const CONFIG_QUERY = gql`
  query configQuery {
    config {
      id
      pluginEnabled
      createdAt
      position
      merchant
      height
      showOnName
      showOnRegexSource
      backgroundColor {
        r
        g
        b
        a
      }
    }
    getPlan {
      id
      currentPeriodEnd
      name
      status
    }
  }
`;

const Customize: React.FC<CustomizeProps> = () => {
  const { data, error, loading } = useQuery(CONFIG_QUERY);

  if (loading) {
    return (
      <Wrapper title="Customize">
        <Page fullWidth>
          <Layout>
            <Layout.Section secondary>
              <Card>
                <Card.Section>
                  <SkeletonBodyText lines={5} />
                </Card.Section>
              </Card>
              <Card>
                <Card.Section>
                  <SkeletonBodyText lines={5} />
                </Card.Section>
              </Card>
              <Card>
                <Card.Section>
                  <SkeletonBodyText lines={5} />
                </Card.Section>
              </Card>
              <Card>
                <Card.Section>
                  <SkeletonBodyText lines={5} />
                </Card.Section>
              </Card>
              <Card>
                <Card.Section>
                  <SkeletonBodyText lines={5} />
                </Card.Section>
              </Card>
            </Layout.Section>
            <Layout.Section>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Spinner />
              </div>
            </Layout.Section>
          </Layout>
        </Page>
      </Wrapper>
    );
  }

  if (error) {
    return <p>error: {error.message}</p>;
  }

  delete data.config.__typename;
  delete data.config.merchant;

  return (
    <Wrapper title="Customize">
      <Page fullWidth>
        <CustomizerContainer
          initialState={data.config}
          appSubscription={data.getPlan}
        />
      </Page>
    </Wrapper>
  );
};

export default Customize;
