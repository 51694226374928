import * as React from "react";
// BugSnag
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";
import Cookie from "js-cookie";

// Setup Bugsnag
export const bugsnagClient = bugsnag({
  apiKey: process.env.REACT_APP_BUG_SNAG_API || "46f39ebde9ca263a6109bf585873d954",
  // appVersion: process.env.REACT_APP_APP_VERSION,
  appType: "frontend",
});

bugsnagClient.use(bugsnagReact, React);

bugsnagClient.metaData = {
  merchant: {
    name: Cookie.get(`${process.env.REACT_APP_CLIENT_ID}-shop-name`),
  },
};
bugsnagClient.user = {
  id: Cookie.get(`${process.env.REACT_APP_CLIENT_ID}-shop-name`),
  name:
    Cookie.get(`${process.env.REACT_APP_CLIENT_ID}-user-firstName`) +
    " " +
    Cookie.get(`${process.env.REACT_APP_CLIENT_ID}-user-lastName`),
  email: Cookie.get(`${process.env.REACT_APP_CLIENT_ID}-user-email`),
};
