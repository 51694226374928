export default [
  {
    name: 'simple-scroll-to-top',
    dev: 'a639a449ce9093990bc956927df71515',
    prod: '9e602feef50c504551bd76269e1e7ed4',
    serverAddress: null,
    plans: {
      FREE: { name: 'FREE', placeValue: 0, price: 0, annualPrice: 0 },
      PRO: {
        name: 'PRO',
        placeValue: 1,
        price: 2.0,
        trailDays: 7,
        annualPrice: 20.0,
      },
    },
  },
  {
    name: 'slick-scroll-progress-bar',
    dev: '9c0ff7d7e38d1d8340b79e4dd5636f25',
    prod: '6d5e3ca24c9378643a9f6b99f678b861',
    serverAddress: null,
    plans: {
      FREE: { name: 'FREE', placeValue: 0, price: 0, annualPrice: 0 },
      PRO: {
        name: 'PRO',
        placeValue: 1,
        price: 2.0,
        trailDays: 7,
        annualPrice: 20.0,
      },
    },
  },
  {
    name: 'realtime-low-stock-alerts',
    dev: 'ca7c272141d20f3a086a2e043ca3d9de',
    prod: '19eabd818a8e5706318d976695a1d54b',
    serverAddress: null,
    plans: {
      FREE: { name: 'FREE', placeValue: 0, price: 0 },
      PRO: { name: 'PRO', placeValue: 1, price: 2.0 },
    },
  },
  {
    name: 'social-share-buttons-pro',
    dev: '008a9aab667907cdb0c7e1764c9df8d8',
    prod: '',
    serverAddress: null,
    plans: {
      FREE: { name: 'FREE', placeValue: 0, price: 0 },
      PRO: { name: 'PRO', placeValue: 1, price: 2.0 },
    },
  },
  {
    name: 'easy-order-exports',
    dev: 'fc3540b01377d646e314a70ec878e83a',
    prod: '4479cc12fe98d79a29076365f9075420',
    serverAddress: null,
    plans: {
      FREE: { name: 'FREE', placeValue: 0, price: 0 },
      PRO: { name: 'PRO', placeValue: 1, price: 2.0 },
    },
  },
  {
    name: 'helpmagnet',
    dev: 'f29c170e8349c12070f65e23e454954d',
    prod: '1f9fc35154a22e4595b014d6cf0e5dab',
    serverAddress: 'https://api.helpmagnet.codeword.tech/',
    plans: {
      FREE: {
        name: 'FREE',
        placeValue: 0,
        price: 0,
        annualPrice: 0,
      },
      PRO: {
        name: 'PRO',
        placeValue: 1,
        trailDays: 7,
        price: 12.0,
        annualPrice: 108.0,
      },
      BUSINESS: {
        name: 'BUSINESS',
        placeValue: 2,
        trailDays: 7,
        price: 29.0,
        annualPrice: 264.0,
      },
    },
  },
  {
    name: 'superbar',
    dev: 'c16a02533602ef9dade26d21c8f4aa55',
    prod: '7147baad0a73265200b9d28c3477ad69',
    serverAddress: 'https://api.superbar.codeword.tech/',
    plans: {
      FREE: { name: 'FREE', placeValue: 0, price: 0, annualPrice: 0 },
      PRO: { name: 'PRO', placeValue: 1, price: 5.0, annualPrice: 50.0 },
    },
  },
  {
    name: 'salespeak',
    dev: 'bedd15169c6f7f65333e622d7e072b36',
    prod: '63e5831c629cf0c2f43eda2a728af2af',
    serverAddress: 'https://api.salespeak.codeword.tech/',
    plans: {
      FREE: { name: 'FREE', placeValue: 0, price: 0 },
      PRO: {
        name: 'PRO',
        placeValue: 1,
        usagePricing: {
          cappedAmount: 100,
          terms:
            'After 1000 emails and 100 sms, $10 for every 1000 emails and $10 for every 100 message',
        },
        price: 2.0,
      },
    },
  },
  {
    name: 'sticky-floating-cart-button',
    dev: '038aae1e6b709f815be1cb8d425ba263',
    prod: 'b6986c05c832deaa92a7d85174e34a8c',
    serverAddress: 'https://api.sticky-floating-cart-button.codeword.tech/',
    plans: {
      FREE: { name: 'FREE', placeValue: 0, price: 0 },
      PRO: { name: 'PRO', placeValue: 1, price: 2.0 },
    },
  },
  {
    name: 'uplink',
    dev: 'ec1bf9ea8ef46bdee9bcd3d8a37d5019',
    prod: '6b7d72921e0a0adc5d164e12ce3ef906',
    serverAddress: 'https://api.uplink.codeword.tech/',
    plans: {
      FREE: { name: 'FREE', placeValue: 0, price: 0 },
      PRO: { name: 'PRO', placeValue: 1, price: 2.0 },
    },
  },
]
