// MyRouter.tsx
import React from "react";
import { RouterProps, withRouter } from "react-router";
import { ClientRouter } from "@shopify/app-bridge-react";

function MyRouter(props: RouterProps) {
  const { history } = props;
  return <ClientRouter history={history} />;
}
// @ts-ignore
export default withRouter(ClientRouter);
