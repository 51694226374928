import { Layout, Page } from "@shopify/polaris";
import * as React from "react";
import Wrapper from "../Wrapper";
import GetStartedWidget from "./GetStarted/GetStartedWidget";
import WelcomeHero from "./WelcomeHero";
import AppListWidget from "./widgets/AppListWidget/AppListWidget";
import CommunityWidget from "./widgets/CommunityWidget/CommunityWidget";
import SupportWidget from "./widgets/SupportWidget/SupportWidget";

class Dashboard extends React.Component {
  render() {
    return (
      <Wrapper title="Dashboard">
        <Page>
          <WelcomeHero />
          <Layout>
            <Layout.Section>
              <GetStartedWidget />
              <AppListWidget />
            </Layout.Section>
            <Layout.Section secondary>
              {/* <StatsWidget /> */}
              <SupportWidget />
              {/* <CommunityWidget /> */}
            </Layout.Section>
          </Layout>
        </Page>
      </Wrapper>
    );
  }
}

export default Dashboard;
