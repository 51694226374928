import * as React from "react";
import {
  Form,
  Card,
  Select,
  RangeSlider,
  ChoiceList,
  TextField,
} from "@shopify/polaris";
import ColorPicker from "../../ColorPicker";

export interface ConfigPanelProps {
  formState: Record<string, any>;
  handleChange: (partialState: Partial<ConfigPanelProps["formState"]>) => void;
}

const positionOptions = [
  { label: "Top", value: "TOP" },
  { label: "Bottom", value: "BOTTOM" },
];

export default function ConfigPanel({
  formState,
  handleChange,
}: ConfigPanelProps) {
  return (
    <Form onSubmit={() => {}}>
      <Card title="Positioning">
        <Card.Section>
          <Select
            label="Position"
            options={positionOptions}
            onChange={(value) => {
              handleChange({ position: value });
            }}
            value={formState.position}
          />
        </Card.Section>
      </Card>

      <Card title="Design">
        <Card.Section>
          <ColorPicker
            stateItem="backgroundColor"
            color={formState.backgroundColor}
            label="Background Color"
            handleChange={(value) => handleChange({ backgroundColor: value })}
          />
          <RangeSlider
            output
            label="Height"
            value={formState.height}
            onChange={(value) => {
              handleChange({ height: value });
            }}
          />
        </Card.Section>
      </Card>
      <Card title="Display Settings">
        <Card.Section>
          <ChoiceList
            title="Where to display the bar"
            onChange={(values) => {
              const [value] = values;
              if (value === "homePage") {
                handleChange({ showOnName: value, showOnRegexSource: "" });
              } else if (value === "allPages") {
                handleChange({ showOnName: value, showOnRegexSource: "" });
              } else {
                handleChange({ showOnName: value, showOnRegexSource: "" });
              }
            }}
            choices={[
              { label: "All pages", value: "allPages" },
              { label: "Home page", value: "homePage" },
              {
                label: "Show if page's URL exactly matches this URL",
                value: "urlMatch",
              },
              {
                label: "Show if page's URL contains this string in URL",
                value: "stringMatch",
              },
            ]}
            selected={formState.showOnName}
          />

          {formState.showOnName !== "allPages" &&
            formState.showOnName !== "homePage" && (
              <TextField
                label={
                  formState.showOnName === "urlMatch"
                    ? "Enter the url of page/product/collection"
                    : "Enter the text to match in url"
                }
                value={formState.showOnRegexSource}
                helpText={
                  formState.showOnName === "urlMatch"
                    ? "Eg. If you want to show the app only on https://yourstore.com/collections/tshirts enter '/collections/tshirts'"
                    : "Eg. If you want to show the app on all collections https://yourstore.com/collections enter '/collections'"
                }
                onChange={(value) => {
                  handleChange({
                    showOnRegexSource: value,
                  });
                }}
              />
            )}
        </Card.Section>
      </Card>
    </Form>
  );
}
