import getShopName from "./getShopName";
import createApp from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";

export default function redirectToGetAccessToken() {
  if (window.location !== window.parent.location) {
    const app = createApp({
      apiKey: process.env.REACT_APP_CLIENT_ID || "",
      shopOrigin: getShopName(),
    });
    Redirect.create(app).dispatch(
      Redirect.Action.REMOTE,
      `${
        process.env.REACT_APP_SERVER_URI
      }api/authV2/authorize-with-shopify?shop=${getShopName()}&clientId=${
        process.env.REACT_APP_CLIENT_ID
      }&scopes=${process.env.REACT_APP_SCOPES}&callback=https://${
        window.location.host
      }/login`
    );
  } else {
    // @ts-ignore
    window.location = `${
      process.env.REACT_APP_SERVER_URI
    }api/authV2/authorize-with-shopify?shop=${getShopName()}&clientId=${
      process.env.REACT_APP_CLIENT_ID
    }&scopes=${process.env.REACT_APP_SCOPES}&callback=https://${
      window.location.host
    }/login`;
  }
}
