// React
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
// Shopify
import '@shopify/polaris/styles.css'
import { Provider } from '@shopify/app-bridge-react'
import { AppProvider } from '@shopify/polaris'
import translations from '@shopify/polaris/locales/en.json'
// Apollo
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from 'react-apollo'
import Cookie from 'js-cookie'

// App
import App from './App'
import getShopName from './helpers/getShopName'
import redirectToGetAccessToken from './helpers/redirectToGetAccessToken'
import { bugsnagClient } from './helpers/bugsnagClient'
import ShopifyRouter from './Components/ShopifyRouter'

// Create apollo client to connect to our server
const client = new ApolloClient({
  // DO NOT MERGE IF THIS COMMENT IS THERE
  uri: process.env.REACT_APP_SERVER_URI,
  onError: (err) => {
    console.log(err)
    bugsnagClient.notify(
      new Error(err.graphQLErrors && err.graphQLErrors[0].message),
      {
        metaData: {
          // @ts-ignore
          networkError: err.networkError?.result?.errors,
          graphQLErrors: err.graphQLErrors ? err.graphQLErrors[0].message : '',
          // @ts-ignore
          // headers: err.networkError.response.headers,
          variables: err.operation.variables,
        },
      }
    )
    // If Access Token Is Invalid Get Another One.
    if (
      err.graphQLErrors &&
      err.graphQLErrors[0].message.includes('ACCESS_TOKEN_INVALID')
    ) {
      redirectToGetAccessToken()
    }
  },
  headers: {
    accessToken: Cookie.get(`${process.env.REACT_APP_CLIENT_ID}-access-token`),
    shopName: getShopName(),
  },
})

const ErrorBoundary = bugsnagClient.getPlugin('react')

ReactDOM.render(
  <ErrorBoundary>
    <ApolloProvider client={client}>
      <AppProvider i18n={translations}>
        <Provider
          config={{
            apiKey: process.env.REACT_APP_CLIENT_ID || '',
            shopOrigin: getShopName(),
            forceRedirect: true,
          }}
        >
          <App client={client} />
        </Provider>
      </AppProvider>
    </ApolloProvider>
  </ErrorBoundary>,

  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
