import queryString from "query-string";
import Cookie from "js-cookie";

export default function getShopName() {
  let shopNameQuery =
    queryString.parse(window.location.search).shop || Cookie.get(`${process.env.REACT_APP_CLIENT_ID}-shop-name`) || "";

  if (Array.isArray(shopNameQuery)) {
    shopNameQuery = shopNameQuery.join(" ");
  }
  return shopNameQuery;
}
