import { Frame, Loading } from '@shopify/polaris'
import Cookie from 'js-cookie'
import * as React from 'react'
import Routes from '../../Routes'
import RoutePropagator from './Components/RoutePropagator'
import isAccessTokenValid from './helpers/isAccessTokenValid'
import redirectToGetAccessToken from './helpers/redirectToGetAccessToken'
import ShopifyRouter from './Components/ShopifyRouter'
import { BrowserRouter } from 'react-router-dom'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import { ApolloClient, gql } from 'apollo-boost'
import clientIds from '../server/clientIds'

interface IProps {
  modalActive: boolean
  toggleModal: () => void
}

export const ModalContext = React.createContext({} as IProps)

const GET_PLAN = gql`
  query getPlanApp {
    getPlan {
      id
      name
      status
      currentPeriodEnd
      trial_ends_on
      billing_on
      price
    }
  }
`

interface AppPropType {
  client: ApolloClient<unknown>
}

function getCookie(name: string) {
  return Cookie.get(`${process.env.REACT_APP_CLIENT_ID}-${name}`)
}

function getPlanIntervalByPrice(price: number, plan: string) {
  const currentAppSlug = process.env.REACT_APP_SLUG
  const currentAppPlan = clientIds.find((c) => c.name === currentAppSlug)
  if (currentAppPlan) {
    // @ts-ignore
    const currentPlanData = currentAppPlan.plans[plan]
    return price > currentPlanData.price ? 'ANNUAL' : 'MONTHLY'
  }

  // don;t fail
  return 'MONTHLY'
}

class App extends React.Component<AppPropType> {
  constructor(props: AppPropType) {
    super(props)
    const name = getCookie('user-firstName') + ' ' + getCookie('user-lastName')
    const email = getCookie('user-email') as string
    const appNameSlug = process.env.REACT_APP_SLUG
    const shop = getCookie('shop-name') as string

    this.props.client
      .query({
        query: GET_PLAN,
        variables: {},
      })
      .then((payload) => {
        let plan = null
        let price = null
        let interval = null
        if (payload.data.getPlan) {
          plan = payload.data.getPlan.name
          price = payload.data.getPlan.price
          if (price) {
            interval = getPlanIntervalByPrice(price, plan)
          }
        }

        window
          // @ts-ignore
          .Intercom('boot', {
            app_id: 'g68o4qej',
            name,
            email,
            company: {
              id: shop,
              name: shop,
              website: shop,
              plan: plan,
              price: price,
              interval,
              // @ts-ignore
              [appNameSlug]: true,
            },
          })
        // init logRocket
        LogRocket.init(`okubio/${appNameSlug}`)
        setupLogRocketReact(LogRocket)

        LogRocket.identify(shop, {
          name,
          email,
          // Add your own custom user variables here, ie:
          subscriptionType: plan,
          id: shop,
          website: shop,
          [appNameSlug as any]: true,
        })
      })
      .catch((e) => console.log(e))
  }
  state = {
    modalActive: false,
  }

  openProModal = () => {
    this.setState({ modalActive: !this.state.modalActive })
  }

  render() {
    if (!isAccessTokenValid()) {
      redirectToGetAccessToken()
      return (
        <div style={{ height: '100px' }}>
          <Frame>
            <Loading />
          </Frame>
        </div>
      )
    }
    const modalActive = this.state.modalActive
    const toggleModal = this.openProModal
    const value = { modalActive, toggleModal }

    return (
      <ModalContext.Provider value={value}>
        <BrowserRouter>
          <ShopifyRouter />
          <RoutePropagator />
          <Routes />
        </BrowserRouter>
      </ModalContext.Provider>
    )
  }
}

export const AppContextConsumer = ModalContext.Consumer
export default App
