import * as React from "react";
import SetupSlickProgressBar from "../SetupSlickProgressBar";

export interface GettingStartedProps {}

const GettingStarted: React.SFC<GettingStartedProps> = () => {
  return (
    <div>
      <SetupSlickProgressBar />
    </div>
  );
};

export default GettingStarted;
