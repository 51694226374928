import { Context, TitleBar } from "@shopify/app-bridge-react";
import { Toast } from "@shopify/app-bridge/actions";
import gql from "graphql-tag";
import * as React from "react";
import { useMutation, useQuery } from "react-apollo";
import { getPluginEnabledWrapper } from "./__generated__/getPluginEnabledWrapper";
import { togglePluginEnabledWrapper } from "./__generated__/togglePluginEnabledWrapper";

interface WrapperProps {
  children: React.ReactElement;
  title: string;
}

const CONFIG = gql`
  query getPluginEnabledWrapper01 {
    config {
      id
      merchant
      pluginEnabled
    }
  }
`;

const TOGGLE_ENABLE_PLUGIN = gql`
  mutation togglePluginEnabledWrapper02 {
    togglePluginEnabled {
      id
      merchant
      pluginEnabled
    }
  }
`;

const Wrapper: React.FC<WrapperProps> = ({ children, title }) => {
  const app = React.useContext(Context);
  if (app === null) throw new Error("Can;t find the app context");
  const { data, error } = useQuery<getPluginEnabledWrapper>(CONFIG);
  const [toggleConfig, { loading }] =
    useMutation<togglePluginEnabledWrapper>(TOGGLE_ENABLE_PLUGIN);

  if (error) return <div>Error</div>;

  let isPluginEnabled = false;

  if (data && data.config) {
    isPluginEnabled = data.config.pluginEnabled;
  }

  // Show Toast
  const toastNotice = Toast.create(app, {
    message: `Plugin ${isPluginEnabled ? "Disabled" : "Enabled"}`,
    duration: 5000,
  });

  const hideEnableButton = process.env.REACT_APP_HIDE_ENABLE_APP_BUTTON;

  return (
    <>
      {!hideEnableButton && (
        <TitleBar
          title={title}
          // P.S. Primary action doesnt support loading attribute
          primaryAction={{
            content: isPluginEnabled ? "Disable App" : "Enable App",
            disabled: loading,
            destructive: isPluginEnabled ? true : false,
            onAction: async () => {
              await toggleConfig();
              toastNotice.dispatch(Toast.Action.SHOW);
            },
          }}
        />
      )}
      {hideEnableButton && <TitleBar title={title} />}
      {children}
    </>
  );
};
export default Wrapper;
