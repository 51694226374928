import { Card, DisplayText, TextContainer } from "@shopify/polaris";
import * as React from "react";
import "./GetStartedWidget.css";
import { EnablePluginSection } from "./EnablePluginSection";
import ReviewSection from "./ReviewSection";
import UpgradePlanSection from "./UpgradePlanSection";
import GettingStarted from "../../../../../components/GettingStarted/GettingStarted";

export interface GetStartedWidgetProps {}

const GetStartedWidget: React.SFC<GetStartedWidgetProps> = () => {
  let isDone = localStorage.getItem("announcement_bar_has_rated");
  return (
    <div className="current-app-widget">
      <Card>
        <Card.Section>
          <DisplayText size="medium">Let's Get Started</DisplayText>
          <div style={{ marginBottom: "1rem" }}></div>
          <TextContainer>
            <p>
              Awesome! Now that the app is installed successfully, you'll want
              to get started with configuring the app and enabling it. Follow
              the steps below to get started.
            </p>
          </TextContainer>
        </Card.Section>
        <GettingStarted />
        <EnablePluginSection />
        {/* <ReviewSection /> */}
        {/* <UpgradePlanSection /> */}
      </Card>
      <br />
    </div>
  );
};

export default GetStartedWidget;
