import { Context } from '@shopify/app-bridge-react'
import { Redirect } from '@shopify/app-bridge/actions'
import { Button, DisplayText, Modal, Stack, TextStyle } from '@shopify/polaris'
import * as React from 'react'
import Emoji from './Emoji'

export interface UpgradePlanModalProps {
  active: boolean
  handleClose: () => void
  resetFunction: () => void
}

function UpgradePlanModal(props: UpgradePlanModalProps): JSX.Element {
  const { resetFunction, handleClose, active } = props
  const app = React.useContext(Context)
  if (!app) {
    throw new Error('shopify app provider not found')
  }
  const redirect = Redirect.create(app)

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title={'Unlock Premium Features'}
      secondaryActions={[
        {
          content: 'Reset Changes',
          onAction: () => {
            resetFunction()
          },
        },
      ]}
    >
      <Modal.Section>
        <div style={{ textAlign: 'center' }}>
          <Stack vertical spacing="loose">
            <Emoji
              unicode="1f513"
              style={{
                width: '110px',
              }}
            />
            <DisplayText size="large">
              You Discovered a Premium Feature!
            </DisplayText>
            <TextStyle variation="subdued">
              Looks like you have selected some settings not included in your
              current plan. Please upgrade your plan to keep the settings or
              press the reset button to revert premium changes.
            </TextStyle>
            <Button
              size="large"
              primary
              onClick={() => {
                // first reset the form, so it hides contextual save bar
                resetFunction()
                redirect.dispatch(Redirect.Action.APP, '/pricing')
              }}
            >
              Start Free Trial
            </Button>
          </Stack>
        </div>
      </Modal.Section>
    </Modal>
  )
}

export default UpgradePlanModal
