import Cookie from "js-cookie";
import queryString from "query-string";
export default function saveAccessTokenToCookie(location: { search: string }) {
  // Get access token & shop from query params
  const { shop, access_token, firstName, lastName, email }: Record<string, any> = queryString.parse(location.search);
  // Save access token to cookie
  Cookie.set(`${process.env.REACT_APP_CLIENT_ID}-access-token`, access_token, {
    expires: 1,
    samesite: "none",
    secure: true,
  });
  // Save Shop Name To Cookie
  Cookie.set(`${process.env.REACT_APP_CLIENT_ID}-shop-name`, shop, {
    expires: 1,
    samesite: "none",
    secure: true,
  });

  // Save User Deets To Cookie
  Cookie.set(`${process.env.REACT_APP_CLIENT_ID}-user-firstName`, firstName, {
    expires: 1,
    samesite: "none",
    secure: true,
  });
  Cookie.set(`${process.env.REACT_APP_CLIENT_ID}-user-lastName`, lastName, {
    expires: 1,
    samesite: "none",
    secure: true,
  });
  Cookie.set(`${process.env.REACT_APP_CLIENT_ID}-user-email`, email, {
    expires: 1,
    samesite: "none",
    secure: true,
  });
  // Redirect to homepage
  // @ts-ignore
  window.location = "/";
}
