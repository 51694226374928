import Cookie from "js-cookie";

export default function isAccessTokenValid() {
  const accessToken = Cookie.get(`${process.env.REACT_APP_CLIENT_ID}-access-token`);

  // Ignore Login Page Hack
  // TODO: Find a better solution to not check for access token on login page
  if (window.location.href.includes("access_token")) {
    return true;
  }

  if (accessToken) {
    return true;
  }

  return false;
}
