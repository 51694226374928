import { Button, Card, TextStyle } from "@shopify/polaris";
import * as React from "react";
import "./AppListWidget.css";

export interface AppListWidgetProps {}

export interface AppListItemProps {
  icon: string;
  name: string;
  description: string;
  appListingLink: string;
  installLink: string;
}

const AppListItem: React.FC<AppListItemProps> = ({
  name,
  description,
  icon,
  installLink,
  appListingLink,
}) => {
  return (
    <div className="app-list___item">
      <div>
        <img className="app-list___icon" src={icon} alt={name} />
      </div>
      <div>
        <strong>
          <a className="app-list___link" href={appListingLink} target="_blank">
            {name}
          </a>
        </strong>
        <TextStyle variation="subdued">
          <p>{description}</p>
        </TextStyle>
        <Button url={installLink} size="slim" external primary>
          Set Up App
        </Button>
      </div>
    </div>
  );
};

const AppListWidget: React.FC<AppListWidgetProps> = () => {
  return (
    <div className="app-list___wrapper">
      <Card title="Check out our other apps">
        <div className="app-list___inner">
          <AppListItem
            icon="https://cdn.shopify.com/s/files/applications/7147baad0a73265200b9d28c3477ad69_336x336.png?1588527420"
            name="Superbar"
            description="Powerful announcement bars that increase sales and conversions"
            appListingLink="https://apps.shopify.com/superbar"
            installLink="https://apps.shopify.com/superbar"
          />
          <AppListItem
            icon="https://cdn.shopify.com/s/files/applications/9e602feef50c504551bd76269e1e7ed4_336x336.png?1580800549"
            name="Simple Scroll To Top"
            description="Add a lightweight scroll to top button to your shop"
            appListingLink="https://apps.shopify.com/simple-scroll-to-top"
            installLink="https://apps.shopify.com/simple-scroll-to-top"
          />
          <AppListItem
            icon="https://cdn.shopify.com/s/files/applications/6d5e3ca24c9378643a9f6b99f678b861_336x336.png?1593631598"
            name="Slick Scroll Progress Bar"
            description="Add a scroll progress bar for shops with large catalogues"
            appListingLink="https://apps.shopify.com/slick-scroll-progress-bar"
            installLink="https://apps.shopify.com/slick-scroll-progress-bar"
          />
        </div>
      </Card>
    </div>
  );
};

export default AppListWidget;
