import * as React from "react";
import { CSSProperties } from "react";

export interface EmojiProps {
  unicode: string;
  style?: CSSProperties;
  fallback?: string;
}

const Emoji: React.SFC<EmojiProps> = props => {
  const { unicode, style = {}, fallback = "1f535" } = props;
  const emojiUnicode = unicode ? unicode : fallback;
  const hexNumberOfUnicode = parseInt(emojiUnicode, 16);
  const nativeEmoji = String.fromCharCode(hexNumberOfUnicode);

  return (
    <img
      style={style}
      src={`https://twemoji.maxcdn.com/v/12.1.5/svg/${emojiUnicode}.svg`}
      alt={`emoji of ${nativeEmoji}`}
    />
  );
};

export default Emoji;
