import * as React from "react";
import "./previewSection.css";
import getShopName from "../../helpers/getShopName";
import queryParam from "query-string";

interface IProps {
  formState: Record<string, any>;
  // these 2 options are compulsary and then you can have more options
  options: { targetId: string; developmentMode?: boolean } & Record<string, any>;
  style: React.CSSProperties;
}

function PreviewSection({ formState, style, options }: IProps) {
  const _options = { shop: getShopName(), developmentMode: true, ...options };
  const optionString = queryParam.stringify(_options);

  React.useEffect(() => {
    function createScript(src: any) {
      const script = document.createElement("script");
      script.setAttribute("src", src);
      script.setAttribute("async", "true");
      script.setAttribute("type", "text/javascript");
      return script;
    }

    function createStyle(src: any) {
      const style = document.createElement("link");
      style.setAttribute("rel", "stylesheet");
      style.setAttribute("href", src);
      style.setAttribute("type", "text/css");
      return style;
    }

    document.body.appendChild(createStyle(`${process.env.REACT_APP_STOREFRONT_URI}/bundle.css`));
    document.body.appendChild(createScript(`${process.env.REACT_APP_STOREFRONT_URI}/bundle.js?${optionString}`));
  }, []);

  return (
    <div className="preview-container">
      <div className="top-bar">
        <div className="circles">
          <div>
            <div className="circle circle-red"></div>
            <div className="circle circle-yellow"></div>
            <div className="circle circle-green"></div>
          </div>
          <div>
            <span>Your Browser Window</span>
          </div>
        </div>
      </div>
      <div style={style} className="preview-container-inner">
        <div style={{ width: "100%" }} data-config={JSON.stringify(formState)} id={options.targetId}></div>
      </div>
    </div>
  );
}

export default PreviewSection;
